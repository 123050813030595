import React, { } from "react"
//import { renderToString } from 'react-dom/server'

//import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import remarkDirective from "remark-directive"
import remarkDirectiveRehype from "remark-directive-rehype"
import gfm from 'remark-gfm'

//import ReactPlayer from 'react-player/wistia'
import "../styles/customMarkdown.scss"
import icon_light from "../assets/starWhiteIcon.webp"
import icon_dark from "../assets/starIconBlue.webp"
import { useRef } from "react"

import loadable from '@loadable/component'
const ReactPlayer = loadable(() => import("react-player/wistia"))

//const gfm = loadable(() => import("remark-gfm"))
//const remarkDirectiveRehype = loadable(() => import("remark-directive-rehype"))
//const remarkDirective = loadable(() => import("remark-directive"))
//const rehypeRaw = loadable(() => import("rehype-raw"))

const ReactMarkdown = loadable(() => import("react-markdown"))






const CustomMarkdown = ({ children, className, skipHtml }) => {
    const ref = useRef()

    const YouTubeVideo = ({ id, children }) => (
        <iframe
            src={'https://www.youtube.com/embed/' + id}
            width="640"
            height="360"
            className="custom-youtube-video"
        >
            {children}
        </iframe>
    )

    const CustomLink = ({ children, url, title, rel, target }) => (

        <a href={(url) ? url : //if is using URL - set url
            (children && children[0] && typeof children[0] == 'object' && children[0].props && children[0].props.href) ? children[0].props.href : // this is to grab de href from React object element
                children //otherwise just set children whatever is
        }
            target={target} rel={rel}>
            {title}
        </a>
    )

    const CheckedListItem = ({ children }) => (

        <div className="checked-list-item">{children}</div>

    )

    const CTA = ({ children, title, rel, target, url }) => (
        <div className="customMarkdown_cta" >
            <a href={(url) ? url : //if is using URL - set url
                (children && children[0] && typeof children[0] == 'object' && children[0].props && children[0].props.href) ? children[0].props.href : // this is to grab de href from React object element
                    children //otherwise just set children whatever is
            }
                target={target} className={'customMarkdown_cta-link ' + className} rel={rel}>{title}</a>
        </div>

    )

    const CustomPhoneButton = ({ children, title, rel, color }) => (
        <div className={'customMarkdown-phone-btn ' + color}>
            <a href={'tel:' + children} className={'customMarkdown-phone-btn-link '} rel={rel}>
                <div className="customMarkdown-phone-btn-title">{title}</div>
                <div className="customMarkdown-phone-btn-phone">{children}</div>
            </a>
        </div>
    )
    const CustomPhoneLink = ({ children, rel, color }) => (
        <div className="customMarkdown-phone-link-wrapper">
            <a href={'tel:' + children} className={`customMarkdown-phone-link-${color}`} rel={rel}>
                {children}
            </a>
        </div>


    )

    const ReadMoreTruncate = ({ children }) => (
        <p>{children}...<span>read more</span></p>
    )

    const WistiaPlayer = ({ children, title }) => {
        return (<div>
            {title ? <h2>{title}</h2> : ''}
            <ReactPlayer
                ref={ref}
                className="storyPlayer__reactPlayer"
                controls={true}
                url={`https://home.wistia.com/medias/${children}`}
                loop={false}
                playerId={children}
            />
        </div>

        )

    }

    const CustomParagraph = ({ iconLight, iconDark, footerDisclaimer, children, disclaimer, position }) => {

        return <div className="custom-paragraph-container">
            {/* {React.createElement(element, {className: position}, children)} */}
            {
                iconLight ?
                    <div className={position}>
                        <img src={icon_light} alt="icon" className="icon-image" />
                        <p className={position}>{footerDisclaimer ? <sup>{footerDisclaimer}</sup> : ""} {children} {disclaimer ? <sup>{disclaimer}</sup> : ""}</p>
                    </div>
                    :
                    iconDark ?
                        <div className={position}>
                            <img src={icon_dark} alt="icon" className="icon-image" />
                            <p className={position}>{footerDisclaimer ? <sup>{footerDisclaimer}</sup> : ""} {children} {disclaimer ? <sup>{disclaimer}</sup> : ""}</p>
                        </div>
                        :
                        
                        <p className={position}>{footerDisclaimer ? <sup>{footerDisclaimer}</sup> : ""} {children} {disclaimer ? <sup>{disclaimer}</sup> : ""}</p>
            }
            

        </div>


    }




    return (
        <>
            <ReactMarkdown
                children={children}
                remarkPlugins={[remarkDirective, remarkDirectiveRehype, gfm]}

                // remarkPlugins={[remarkDirective, remarkDirectiveRehype, gfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                    'youtube-video': YouTubeVideo,
                    'custom-link': CustomLink,
                    'read-more-truncate': ReadMoreTruncate,
                    'cta': CTA,
                    'checked-list-item': CheckedListItem,
                    'custom-phone-btn': CustomPhoneButton,
                    'custom-paragraph': CustomParagraph,
                    'custom-phone-link': CustomPhoneLink,
                    'wistia-video': WistiaPlayer

                }}
                className={className}
                skipHtml={skipHtml}

            />
        </>
    )
}

export default CustomMarkdown